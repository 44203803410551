// @flow

import React from 'react';
import { Link, graphql } from 'gatsby';

import type { PageProps } from '../types';

import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Tag from '../components/tag';
import { rhythm } from '../utils/typography';

function BlogIndex({ data, location }: PageProps) {
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" keywords={[`blog`]} />
      <Bio />
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug;
        return (
          <div key={node.fields.slug} className="post">
            <h3
              style={{
                marginBottom: rhythm(1 / 4),
              }}
            >
              <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                {title}
              </Link>
            </h3>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <small>{node.frontmatter.date}</small>
              <span style={{ paddingLeft: '8px', paddingRight: '8px' }}>•</span>
              {(node.frontmatter.tags || []).map(tag => (
                <Tag key={tag} tag={{ fieldValue: tag, totalCount: 0 }} />
              ))}
            </div>
            <p
              dangerouslySetInnerHTML={{
                __html: node.frontmatter.description || node.excerpt,
              }}
            />
          </div>
        );
      })}
    </Layout>
  );
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
  }
`;
