// @flow

import React from 'react';

import kebabCase from 'lodash/kebabCase';

import { Link } from 'gatsby';

import type { Tag as TagType } from '../types';

import '../styles/tag.css';

type Props = {
  tag: TagType,
};

const Tag = ({ tag }: Props) => {
  const kebabField = kebabCase(tag.fieldValue);

  return (
    <div className="tag" data-tag={kebabField} key={tag.fieldValue}>
      <Link to={`/tags/${kebabField}/`}>
        {`# ${tag.fieldValue}`}
        {tag.totalCount ? ` (${tag.totalCount})` : null}
      </Link>
    </div>
  );
};

export default Tag;
